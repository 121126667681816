body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #111827;
  color: white;
}

a {
  color: #3b82f6;
  text-decoration: none;
}

a:hover {
  color: #2563eb;
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  color: white;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 1.875rem;
}

h3 {
  font-size: 1.5rem;
}
.logo {
  width: 40px;
  height: 40px;
}